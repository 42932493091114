<template>
	<div class="row mt-3">
		<label class="col-sm-4 col-form-label" for="season">{{ $t('gynecologie.saison') }} *</label>
		<div class="col-sm-8">
			<e-select
				:disabled="disabled"
				v-model="season_local"
				id="season_id"
				track-by="season_id"
				label="season_label"
				:placeholder="$t('monte.rechercher_saison')"
				:selectedLabel="$t('global.selected_label')"
				:options="seasons"
				:allow-empty="false"
				:show-labels="false"
				:loading="loadingSeason"
				:class="{ 'is-invalid': errors && errors.indexOf('season_local') > -1 }"
			></e-select>
		</div>	
	</div>
</template>

<script type="text/javascript">
	
	import { EventBus } from 'EventBus'
	import Navigation from "@/mixins/Navigation.js"
	import Tools from "@/mixins/Tools.js"

	export default {
		name: 'Saison',
		mixins: [Navigation, Tools],
		props: {
			all_season: {
				type: Array,
				default: () => ( [] )
			},
			season:{
				type: Object,
				default: () => ( null )
			},
			value:{
				type: Object,
				default: () => ( null )
			},
			disabled:{
				type: Boolean,
				default: () => ( false )
			},
		},
		data () {
			return {
                seasons: [],
                loadingSeason: false,
                errors: []
			}
		},

		computed: {
			season_local: {
				get() {
					return this.value
				},
				set(val) {
					this.$emit('input', val)
				}
			},
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.season_local = this.season
				if(this.season) {
					this.seasons = [this.season]
				}
				if(this.all_season.length > 0) {
					this.seasons = this.all_season
				}
				this.loadSeason()
			},

			loadSeason() {
				this.loadingSeason = true
				this.season_local = null
				if(this.season && this.season.season_id){
					this.season_local = this.seasons.find(season => season.season_id == this.season.season_id)
				}
				else{
					this.season_local = this.seasons.find(season => season.season_default == true)
				}
				this.loadingSeason = false
			}

		},
		watch: {
			all_season: {
				deep: true,
				handler(val) {
					this.seasons = val
					this.loadSeason()
				}
			},
			season(val) {
				this.season_local = val
				this.loadSeason()
			}
		},

		components: {

		}
	}

</script>